<template>
  <footer class="d-flex flex-column bg-dark text-light">
    <div class="container-fluid d-flex flex-md-row flex-column p-4">
      <div class="col-lg-3 px-3">
        <router-link to="/">
          <img src="../../assets/logo/MK-CO-2-TW.svg" class="logo-MK-CO" />
        </router-link>
        <p>
          LE partenaire pour l’atteinte de VOS objectifs. Commençons ensemble à
          les déterminer et les accomplir…
        </p>
        <hr />
        <h4 class="text-center">Rester informé <IcoBell /></h4>
        <label for="inputMail2" class="my-2">Mail</label>
        <form class="input-group input-group-sm mb-3" @submit.prevent="confirm">
          <input
            type="email"
            required
            class="form-control"
            placeholder="nom@mail.com"
            aria-label="nom@mail.com"
            aria-describedby="button-addon2"
            v-model="form.mail_news"
            id="inputMail2"
          />
          <button type="submit" class="btn btn-outline-primary">Pret !</button>
        </form>
      </div>
      <div class="col-lg-9">
        <div
          class="d-flex flex-md-row flex-column text-md-end text-center lh-lg"
        >
          <div class="col p-3">
            <h4 class="text-primary">Menu</h4>
            <div class="d-flex flex-column">
              <router-link to="/">Découvrir</router-link>
              <router-link
                :to="`/accompagnement/${services[0] && services[0].slug}`"
                >Accompagnement</router-link
              >
              <router-link to="/offre-emploi">Talents</router-link>
              <router-link to="/articles">S'informer</router-link>
              <router-link to="/se-connecter">Mon espace</router-link>
            </div>
          </div>
          <div class="col p-3">
            <h4 class="text-primary">En savoir plus sur nous</h4>
            <div class="d-flex flex-column">
              <div
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#mentionLegale"
              >
                Mentions Légales
              </div>
              <div>Politique de confidentialité</div>
              <div
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#conditionGenerale"
              >
                Conditions Générales
              </div>
              <div
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#siteMap"
              >
                Plan du site
              </div>
            </div>
          </div>
          <div class="col p-3">
            <h4 class="text-primary">Nous joindre facilement</h4>
            <div class="d-flex flex-column">
              <div>II M A35 101-Antananarivo</div>
              <div>Antsakaviro</div>
              <div>+261 32 11 888 95</div>
              <div>votre-objectif@mk-co.org</div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end px-3">
          <h5>Nous sommes aussi sur :</h5>
          <div class="d-flex iconSocial">
            <a
              class="m-3 ms-0"
              href="https://web.facebook.com/MKCOPartner"
              target="_blank"
              rel="noopener noreferrer"
              ><IcoFacebook
            /></a>
            <a
              class="m-3"
              href="https://www.instagram.com/mkcopartner/"
              target="_blank"
              rel="noopener noreferrer"
              ><IcoInstagram
            /></a>
            <a
              class="m-3"
              href="https://api.whatsapp.com/message/SYIFLTHVSMGOI1?autoload=1&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              ><IcoWhatsapp
            /></a>
            <a
              class="m-3 me-0"
              href="https://www.linkedin.com/company/mk-co-partner"
              target="_blank"
              rel="noopener noreferrer"
              ><IcoLinkedin
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-secondary text-muted text-center p-2">
      <small>Tous droits réservés ©mk-co {{ new Date().getFullYear() }}</small>
    </div>
    <div
      :class="[
        'retourHaut',
        {
          retourHautShow: scrollPosition > 250,
        },
      ]"
      @click="scrollToTop"
    >
      <button class="btn btn-primary shadow rounded-circle p-2">
        <IcoUp />
      </button>
    </div>
    <div
      class="modal fade"
      id="mentionLegale"
      tabindex="-1"
      aria-labelledby="mentionLegaleLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-md modal-dialog-scrollable">
        <div class="modal-content border-0">
          <div class="modal-header bg-dark">
            <img
              src="https://www.linkpicture.com/q/MK-CO-2-W.svg"
              alt=""
              width="60px"
              height=""
              class="d-inline-block align-text-top"
            />
            <div
              class="modal-title text-md text-primary"
              id="mentionLegaleLabel"
            >
              MENTIONS LEGALES SITE WWW.MK-CO.ORG
            </div>
            <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>
          <div class="modal-body text-light bg-secondary">
            <p class="lh-sm">
              Les présentes mentions légales sont portées à la connaissance des
              visiteurs et utilisateurs, ci-après « L’utilisateur », du site
              https://www.mk-co.org, ci-après « Le Site »
            </p>
            <h6>Article 1 : L'EDITEUR</h6>
            <p class="lh-sm">
              L'Edition du Site est assurée par l'équipe Digitalisation de MK-co
            </p>
            <h6>Article 2 : L'HEBERGEUR</h6>
            <p class="lh-sm">
              Le site de MK-co est hébergé sur PlanetHoster, dont les contacts
              et informations sont consultables sur ce lien :
              https://www.planethoster.com/fr/Contact
            </p>
            <h6>Article 3 : ACCES AU SITE</h6>
            <p class="lh-sm">
              Le nom et le logo affichés sur le Site sont la propriété de MK-co.
              L’Utilisateur peut accéder au site en tout lieu, 24h/24, 7j/7 sauf
              en cas de force majeure, interruption programmée ou non, et
              pouvant entraîner la nécessité d’une maintenance. En cas de
              modification, interruption ou suspension du site, l’Editeur ne
              saurait être tenu responsable. Néanmoins, il s’engage à faire tout
              son possible à assurer le bon fonctionnement du site.
              L’Utilisateur reconnait que MK-co n’a aucun contrôle sur les
              matériaux disponibles sur Internet et n’est nullement responsable
              des matériaux auxquels il peut avoir accès via le Site.
              L’accessibilité de contenus via le Site ne signifie pas pour
              autant que MK-co les cautionne. L’Utilisateur s’engage à ne causer
              aucun désagrément, ennui ou inconvénient, par quelque moyen que ce
              soit, à MK-co, à l’un de ses clients ou à l’un des utilisateurs du
              site www.mk-co.org et à ne pas laisser sciemment d’autres
              personnes causer de tels désagréments, ennuis ou inconvénients. Il
              s’engage également à ne pas utiliser le Site pour transmettre ou
              afficher des documents diffamatoires, choquants, obscènes,
              menaçants ou susceptibles, à notre avis, de causer des
              désagréments, ennuis ou inconvénients à MK-co ou à toute autre
              personne. L’utilisateur n’est pas autorisé à utiliser le site
              d’une manière susceptible de l’interrompre, de l’endommager ou de
              le rendre moins efficace ou performant, en partie ou en totalité,
              de quelque façon que ce soit.
            </p>
            <h6>Article 4 : COLLECTE ET UTILISATION DES DONNEES</h6>
            <p class="lh-sm">
              Le traitement et la collecte des données personnelles de
              l’Utilisateur par le Site sont faits dans le respect de la vie
              privée. L’Utilisateur dispose également de droits d’accès, de
              modification et de suppression de ses données personnelles. Toute
              utilisation, reproduction, diffusion, commercialisation,
              modification partielle ou totale du Site, sous quelque forme que
              ce soit et par quelque moyen que ce soit, sans que la liste soit
              limitative, sans autorisation de MK-co sont prohibées et pourront
              entrainer des poursuites judiciaires. Lorsque l’Utilisateur envoie
              des documents par le biais du site, par courrier électronique ou
              par un autre moyen, il le fait en sachant que MKco peut copier,
              modifier ou distribuer ces documents, ou créer des produits
              dérivés à partir de ceux-ci, sous quelque forme que ce soit, sans
              que sa responsabilité puisse être engagée. L’Utilisateur s’engage
              également à n’intenter aucune action en rapport avec ces
              documents. L’Utilisateur garantit que ces documents sont aptes à
              être publiés et s’engage à indemniser la Société MK-co si un tiers
              intente contre cette dernière une action en rapport avec ce que
              l’Utilisateur a envoyé. Il admet également en toute connaissance
              de cause que MK-Co peut publier les documents qu’il leur a envoyés
              et/ou utiliser ces documents ou tout concept décrit dans ceux-ci
              dans leurs produits ou services, sans que leur responsabilité
              puisse être engagée; l’utilisateur s’engage également à n’intenter
              aucune action contre nous en rapport avec ces documents.
            </p>
            <h6>Article 5 : APPLICATION DES MENTIONS</h6>
            <p class="lh-sm">
              Les présentes mentions légales valent toute durée et tout
              territoire Toute modification est accessible au public et n'engage
              en aucun cas l'annulation des souscriptions au precedent document
            </p>
          </div>
          <div class="modal-footer bg-dark">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="conditionGenerale"
      tabindex="-1"
      aria-labelledby="conditionGeneraleLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-md modal-dialog-scrollable">
        <div class="modal-content border-0">
          <div class="modal-header bg-dark">
            <img
              src="https://www.linkpicture.com/q/MK-CO-2-W.svg"
              alt=""
              width="60px"
              height=""
              class="d-inline-block align-text-top"
            />
            <div
              class="modal-title text-md text-primary"
              id="mentionLegaleLabel"
            >
              CONDITIONS GENERALES
            </div>
            <!-- <h5 class="modal-title" id="conditionGeneraleLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>
          <div class="modal-body text-light bg-secondary">
            <h6>Article 1 : OBJET et CHAMP D'APPLICATION</h6>
            <p class="lh-sm">
              Les présentes Conditions générales ont pour objet de définir les
              droits et obligations respectifs de la Société et du Client dans
              le cadre de la Prestation des Services et/ou Produits, en vue de
              collaborations entre les deux parties pour toute durée et en tout
              territoire. La société a pour but d’accompagner le Client pour
              atteindre leur objectif en matière de : Comptabilité-Finance,
              Administration-Juridique, Ressources Humaines, Marketing-Commerce,
              Communication-Image, Orientation Professionnelle, Gestion de
              projet, Digitalisation.
            </p>
            <ul>
              <li><u>Raison Sociale</u> : MK-co SARLU</li>
              <li><u>NIF/STAT</u> : 5011706850 - 82110112022010264</li>
              <li>
                <u>Adresse</u> : II M 3A Antsakaviro Ambodirotra Antananarivo
              </li>
              <li><u>Téléphone</u> : 032 11 888 95</li>
              <li><u>Mail</u> : votre-objectif@mk-co.org</li>
              <li><u>Site web</u> : https://www.mk-co.org</li>
            </ul>
            <h6>Article 2 : DEFINITIONS</h6>
            <p class="lh-sm">
              Dans les présentes Conditions générales, les termes suivants ont,
              sauf précision contraire, la signification suivante :
            </p>
            <ul>
              <li>
                <b>Client</b> : personne physique ou morale qui bénéficie des
                Services/Produits proposé par MK-co SARLU
              </li>
              <li>
                <b>Contrat</b> : désigne les présentes Conditions Générales de
                Prestation, les conditions spécifiques propres au Service, tous
                documents commerciaux auxquels il sera donné valeur
                contractuelle. Ces documents seront remis au Client au moment de
                la conclusion des contrats.
              </li>
              <li>
                <b>Prestation</b> ou <b>Service(s)</b> ou <b>Mission(s)</b> :
                service(s) fourni(s) par la Société
              </li>
              <li>
                <b>Société</b> : MK-co SARLU : partenaire, accompagnateur et
                conseiller pour l’atteinte des objectifs des entreprises avec
                lequel le Client a contracté
              </li>
            </ul>
            <h6>Article 3 : CONCLUSION DU CONTRAT</h6>
            <ul class="no-bullets">
              <li>
                <span>3.1.</span> La conclusion du contrat de prestation des
                Services s’effectue à travers l’acceptation par le Client des
                différentes conditions par la Société MK-co et par l’acceptation
                de cette dernière à rendre un livrable dans les délais convenus
                par les deux parties.
              </li>
              <li>
                <span>3.2.</span> Le Client s’engage à fournir à la Société tous
                les documents nécessaires aux différentes démarches.
              </li>
              <li>
                <span>3.3.</span> Le Client est invité à conserver un exemplaire
                de l’ensemble des documents qui constituent le Contrat.
              </li>
              <li>
                <span>3.4.<u>Tiers payeur</u></span>
                <p>
                  Le tiers payeur est la personne physique ou morale qui
                  s’engage à payer les factures correspondant aux
                  Produits/Services fournis au Client par la Société.<br />
                  Le tiers payeur sera informé des conditions , de l'objet et de
                  l’exécution du contrat ainsi que des éventuelles modifications
                  y rattachées. Pour qu’un tiers payeur soit admis comme
                  débiteur, le Client s’engage à fournir à la Société les
                  documents que celle-ci réclamera lors de la conclusion du
                  contrat.<br />
                  Le Client fournit à la Société une attestation du tiers
                  payeur, par laquelle il s’engage à payer le prix des
                  Produits/Services fournis au Client par la Société. Le Client
                  s’engage à informer le tiers payeur qu’il peut mettre fin à
                  son obligation de paiement par lettre recommandée avec accusé
                  de réception adressée au service clients de la Société.<br />
                  En cas de défaillance du tiers payeur, le Client n’est pas
                  exonéré de son obligation de paiement.
                </p>
              </li>
            </ul>
            <h6>
              Article 4 : TARIFS, FACTURATION MODALITES DES SERVICES/PRODUITS
            </h6>
            <ul class="no-bullets">
              <li>
                <span>4.1.<u>Tarifs</u></span
                ><br />
                <span>4.1.1.</span> Le descriptif et les tarifs des
                Services/Produits sont discutés lors de la conclusion du contrat
                et sont inclus dans chaque offre proposée par la Société ainsi
                que les services optionnels à l’offre.<br />
                <span>4.1.2.</span> Les modifications des tarifs seront
                applicables à tous les contrats et notamment ceux en cours
                d’exécution.<br />
                <span>4.1.3.</span> Dans l’hypothèse d’une hausse des tarifs, le
                Client en sera informé par tous les moyens légalement autorisés,
                au moins, quinze (15) jours avant leur prise d’effet. Il peut
                alors mettre fin à son contrat dans ce délai de quinze (15)
                jours par lettre recommandée avec accusé de réception.
              </li>
              <li>
                <span>4.2.<u>Facturation et paiement</u></span
                ><br />
                <span>4.2.1.</span> Les Services/Produits sont facturés selon le
                type de prestation indiqué à la section 4.4 de l'article 4 :<br />
                Ils sont payables en Ariary, dans le délai maximum porté sur la
                facture et selon le mode de paiement convenu au moment de la
                conclusion du contrat.<br />
                <span>4.2.2.<u>La facture indique :</u></span
                ><br />
                <ul>
                  <li>les redevances de la prestation</li>
                  <li>
                    e cas échéant, les services optionnels souscrits par le
                    Client
                  </li>
                  <li>
                    le montant des règlements déjà passés relatifs au service en
                    cours
                  </li>
                  <li>
                    le montant de la TVA exigible sauf présentation
                    d’attestation de destination à jour
                  </li>
                </ul>
                <span>4.2.3.</span> La Société tient à la disposition du Client
                tout élément justificatif de la facture, selon l’état des
                techniques existantes.<br />
                <span>4.2.4.</span> Tout retard de paiement entraînera
                l’application de plein droit et sans formalité d’un intérêt de
                retard égal à 0,05% des sommes dues par jour de retard jusqu’à
                parfait paiement, et d’un frais de gestion de dossier de
                10.000Ar.<br />
                <span>4.2.5.</span> Selon la modalité de paiement choisie, la
                facture est adressée soit au Client, soit au tiers payeur. La
                désignation d’un tiers payeur nécessite l’accord préalable et
                express de l’Opérateur, ainsi que du tiers payeur, et n’exonère
                pas le Client de son obligation de paiement en cas de
                défaillance du tiers payeur.<br />
                <span>4.2.6.</span> La non réception de facture ne saurait
                dispenser le Client de ses obligations nées des présentes. Les
                services passés hors et au-delà de ceux contractés sont dus et
                seront facturés suivant les conditions tarifaires en vigueur.<br />
              </li>
              <li>
                <span
                  >4.3.<u
                    >Renseignements et réclamations sur les factures</u
                  ></span
                ><br />
                <span>4.3.1.</span> Toute réclamation doit être adressée en
                premier lieu au service financier de MK-co dont l’adresse et le
                numéro figurent sur la facture du Client..<br />
                <span>4.3.2.</span> Le décompte des éléments de facturation
                établi par la Société et servant de base à la facture est
                opposable au Client en tant qu’élément de preuve.<br />
                <span>4.3.3.</span> Le Client dispose de dix jours à compter de
                la réception de la facture pour procéder à toute réclamation. La
                Société tient à la disposition du Client tout élément
                justificatif de la facture. Au-delà de ce délai, la facture est
                supposée avoir été acceptée sans réserve dans toute son
                intégralité.
              </li>
              <li>
                <span>4.4.<u>Types de prestations et leurs modalités</u></span
                ><br />
                Comme prévu dans l'article 1, MK-co a plusieurs offres de
                prestations.<br />
                Chacune des offres a ses spécificités, sa durée et différentes
                modalités qui sont cités dans le document contractuel et
                commercial lié au service précis.<br />
                <span>4.4.1.<u>Mission ciblées :</u></span
                ><br />Sont comprises dans cette catégorie, toutes prestations
                basée sur l’achèvement d'une tâche précise définie entre les
                deux parties lors de la conclusion du contrat.
                <ul>
                  <li>
                    La durée est non-précisée étant donné qu'elle varie selon
                    l'accomplissement, les situations et l'objectif à atteindre
                  </li>
                  <li>La prestation est à payer à la conclusion du contrat</li>
                  <li>
                    Pour les services supplémentaires et/ou spécifiques
                    engendrant des coûts supplémentaires, la Société prévient le
                    Client. Ce dernier devra retourner, dans les trois jours
                    ouvrables suivant la notification, sa réponse pour
                    validation ou refus.
                  </li>
                  <li>
                    Il est considéré comme validation de cette addition,
                    l'absence de retour dans ce temps impartis
                  </li>
                </ul>
                <span>4.4.2.<u>Missions régulières :</u></span
                ><br />Sont comprises dans cette catégorie, toutes prestations
                conclues pour une longue durée et implicant des services bien
                précis effectués à des dates régulières et répétitives.
                <ul>
                  <li>
                    La durée peut être trimestrielle, semestrielle ou annuelle.
                    Elle peut être aussi spécifique non-moins de six mois
                  </li>
                  <li>
                    Les factures sont payables mensuellement, ou par trimestre
                    selon le contrat commercial.
                  </li>
                  <li>
                    Une caution sera obligatoire pour toute mission au delà de
                    six mois.
                  </li>
                  <li>
                    Pour les services supplémentaires et/ou spécifiques
                    engendrant des coûts supplémentaires, la Société prévient le
                    Client. Ce dernier devra retourner, dans les sept jours
                    calendaires suivant la notification, sa réponse pour
                    validation ou refus.
                  </li>
                  <li>
                    Il est considéré comme validation de cette addition,
                    l'absence de retour dans ce temps impartis
                  </li>
                </ul>
                <span>4.4.3.<u>Missions de projets :</u></span
                ><br />Sont comprises dans cette catégorie, toutes prestations
                pour un objectif défini implicant une longue durée d’exécution.
                <ul>
                  <li>
                    La durée est spécifique et étudiées cas par cas entre les
                    deux parties au moment de la conclusion du contrat
                  </li>
                  <li>
                    Les factures générales sont payables par tranches de trois :
                    <ul>
                      <li>50% à la date de la signature</li>
                      <li>25% à la moitié de la prestation</li>
                      <li>25% à la fin de la prestation</li>
                    </ul>
                  </li>
                  <li>
                    La société a le droit d'exiger qu'il y ait un tier garant de
                    paiement selon l'ampleur des travaux
                  </li>
                  <li>
                    Pour les services supplémentaires et/ou spécifiques
                    engendrant des coûts supplémentaires, la Société prévient le
                    Client. Ce dernier devra retourner, dans les quinze jours
                    calendaires suivant la notification, sa réponse pour
                    validation ou refus.
                  </li>
                  <li>
                    Il est considéré comme validation de cette addition,
                    l'absence de retour dans ce temps impartis.
                  </li>
                </ul>
                <span>4.4.4.<u>Missions spécifiques :</u></span
                ><br />Sont comprises dans cette catégorie, toutes prestations
                pour un objectif défini pour une durée courte et définies.
                <ul>
                  <li>
                    La durée est précisée au moment de la conclusion du contrat
                  </li>
                  <li>
                    La facture est payable par tranches de deux ::
                    <ul>
                      <li>60% à la date de la signature</li>
                      <li>40% à la fin de la prestation</li>
                    </ul>
                  </li>
                  <li>
                    Pour les services supplémentaires et/ou spécifiques
                    engendrant des coûts supplémentaires, la Société prévient le
                    Client. Ce dernier devra retourner, dans les trois jours
                    calendaires suivant la notification, sa réponse pour
                    validation ou refus.
                  </li>
                  <li>
                    Il est considéré comme validation de cette addition,
                    l'absence de retour dans ce temps impartis.
                  </li>
                </ul>
              </li>
            </ul>
            <h6 class="mt-6">Article 5 : CHANGEMENT DE MODALITES</h6>
            <ul class="no-bullets">
              <li>
                <span>5.1.</span> Si plusieurs choix de modalités (durée,
                facturation, types) sont proposées, le Client ne peut changer
                son choix en cours d'exécution de la prestation sauf sur une
                prolongation de la durée du contrat.
              </li>
              <li>
                <span>5.2.</span> En cas de retrait de la prestation à laquelle
                le Client a contracté, la Société l’avertira par tout moyen de
                communication et pourra lui proposer une nouvelle offre se
                substituant à l’ancienne deux semaines avant le retrait de
                l’offre.<br />
                En cas d’opposition à ce changement vers la nouvelle offre, le
                Client peut mettre fin à son contrat par lettre recommandée avec
                accusé de réception ou par simple lettre remise contre décharge
                dans les dix (10) jours de la réception de la notification.<br />
                En l’absence de réponse du Client dans ce délai, le Client sera
                migré automatiquement sur la nouvelle offre de prestation.
              </li>
            </ul>
            <h6>Article 6 : SUSPENSION ET RESILIATION DU CONTRAT</h6>
            <ul class="no-bullets">
              <li>
                <span>6.1.<u>Suspension du contrat</u></span
                ><br />
                <span>6.1.1.</span>
                Le défaut total ou partiel de paiement à la date limite convenue
                pour le paiement de toute somme due à la Société au titre du
                présent contrat ou au titre d’autre contrat entraînera de plein
                droit et sans mise en demeure préalable la suspension des
                prestations en cours, objet des contrats. Le défaut de paiement
                dans les 40 jours qui suivent le blocage emporte résiliation du
                contrat et l’exigibilité immédiate de tous les engagements du
                client nés de l’exécution des présentes, sans qu’il soit besoin
                d’une quelconque mise en demeure.<br />
                <span>6.1.2.</span>
                La suspension des Prestations peut intervenir dans les mêmes
                conditions pour des dettes nées d’autres contrats souscrits
                auprès de la Société, que ces conventions soient antérieures ou
                postérieures au présent Contrat.<br />
                <span>6.1.3.</span>
                Pour les missions régulières, en l’absence de dénonciation par
                l’une des parties quinze (15) jours avant la date d’échéance,
                par courrier avec accusé de réception, le contrat est réputé
                être reconduit pour une même durée suivant l’offre souscrite par
                le Client.<br />
                <span>6.1.4.</span>
                Sauf pour le cas de convention de suspension justifiée d'un
                Procès Verbal, les redevances de la Prestation restent dues à la
                Société pendant la période de suspension du Service.<br />
              </li>
              <li>
                <span>6.2.<u>Résiliation du contrat</u></span
                ><br />
                <span>6.2.1.</span>
                Hormis les cas de résiliation convenus par les deux parties
                justifiés de Procès Verbaux, toute résiliation à l’initiative du
                client avant l’échéance des durées convenues sur les contrats
                commerciaux, ouvre droit au paiement immédiat par le client des
                Prestations restants à courir jusqu’à la date d’échéance.<br />
                <span>6.2.2.</span>
                MK-co se réserve le droit de résilier le contrat avant terme
                sans préjudice de tous dommages et intérêts sans que cette liste
                soit limitative en cas de manquement du client à ses obligations
                contractuelles, ainsi que des modifications qui pourraient y
                être apportées, après notification préalable du client par tout
                moyen. Le Client ne pourrait en prétendre à une quelconque forme
                de dédommagement.<br />
                <span>6.2.3.</span>
                MK-co se réserve également le droit de résilier le contrat avant
                terme en cas d’impossibilité technique de raccordement ou pour
                cause de décision administrative. Le client ne peut prétendre à
                une quelconque forme de dédommagement lié à l’impossibilité
                d’exécuter la fourniture du service.<br />
                <span>6.2.4.</span>
                Le Client peut mettre fin au Contrat pendant la période initiale
                d’engagement pour motif légitime figurant dans la liste
                ci-dessous :
                <ul>
                  <li>
                    mise en détention dans un établissement pénitentiaire ;
                  </li>
                  <li>faillite personnelle,</li>
                  <li>
                    cas de force majeure, au sens de la jurisprudence, affectant
                    le Client
                  </li>
                  <li>
                    convention à l'amiable avec la Société, justifiée par un
                    Procès Verbal
                  </li>
                </ul>
                Pour exercer cette faculté de résiliation, le Client devra faire
                parvenir à MK-co avec sa demande de résiliation les pièces
                justificatives y afférentes. La résiliation du contrat prend
                effet au plus tard sept (7) jours après la date de réception de
                la demande du Client.
              </li>
            </ul>
            <h6>Article 7 : FORCE MAJEURE</h6>
            <p>
              Chacune des parties ne pourra être tenue pour responsable de
              l’absence d’exécution de tout ou partie du contrat suite à un
              événement de force majeure tel que défini par la loi et les
              jurisprudences.<br />
              Est considéré comme cas de force majeure tout événement
              imprévisible ou inévitable qui, de par sa nature ou ses
              conséquences , ne peut être évité ni surmonté par la Partie qui
              l'invoque, et qui est de nature à rendre impossible, en tout ou en
              partie, temporairement ou définitivement, l'exécution de ses
              obligations contractuelles : actes de guerre, catastrophes
              naturelles,troubles civils, émeutes, embargo, intempéries
              exceptionnelles, interdictions ou restrictions des autorités
              publiques à la fourniture des services et les prestations.<br />Si
              le cas de force majeure dure plus de trente (30) jours
              consécutifs, il ouvrirait droit à la résiliation de plein droit du
              présent contrat par l’une ou l’autre des parties huit (8) jours
              après l’envoi d’une lettre avec accusé de réception, notifiant
              cette décision.
            </p>
            <h6>Article 8 : OBLIGATIONS ET RESPONSABILITES DU CLIENT</h6>
            <ul class="no-bullets">
              <li>
                <span>8.1.</span> Le Client s’engage expressément et sans
                réserve à :
                <ul>
                  <li>respecter les termes du présent contrat</li>
                  <li>respecter les lois et règlements en vigueur</li>
                  <li>
                    vérifier avant apposition de la signature sur tous contrats
                    et formulaires, l’adéquation de la Prestation à ses besoins
                    et reconnaître avoir reçu de la Société toutes les
                    informations et conseils qui lui était nécessaire pour
                    souscrire au présent engagement en toute connaissance de
                    cause et sans contrainte. Aussi, les choix effectués par le
                    client lors de la commande ainsi qu’éventuellement les
                    changements qu’il demande par la suite, demeurent sous son
                    entière responsabilité.
                  </li>
                  <li>ne pas porter atteinte à l’image de la Société MK-co</li>
                </ul>
              </li>
              <li>
                <span>8.2.</span> Le Client s’engage à fournir, lors de son
                inscription et pendant toute la durée des contrats, des
                informations d’identification exactes et à jour (notamment son
                nom, prénom, adresse et coordonnées bancaires).<br />
                Par conséquent, le Client est seul responsable des données qu’il
                communique à la Société.<br />
                En outre, le Client s’engage à informer la Société dans un délai
                de quinze (15) jours de toute modification des informations
                qu’il lui a fournies lors de la souscription du Contrat, et
                notamment de tout changement de domicile ou d’adresse de
                domiciliation de facturation ou de coordonnées bancaires.<br />
                Le non-respect de cette obligation peut entraîner la résiliation
                de plein droit du contrat sans que le client puisse prétendre à
                une quelconque indemnisation.
              </li>
              <li>
                <span>8.3.</span> Le Client s’engage à payer ou faire payer le
                prix des Services qui lui sont fournis par l’Opérateur selon les
                modalités prévues au Contrat.
              </li>
            </ul>
            <h6>Article 9 : OBLIGATIONS ET RESPONSABILITES DE LA SOCIETE</h6>
            <ul class="no-bullets">
              <li>
                <span>9.1.</span> La Société fera son meilleur effort pour
                effectuer dans les règles de l'art les Prestations conformément
                aux contrats et aux conditions convenues pour chaque Missions,
                il prend les mesures nécessaires au maintien de la continuité et
                de la qualité du Service.<br />
                Les suspensions programmées du service, pour effectuer dans le
                but d'améliorer l'activité de la Société feront l’objet d’un
                avis au client et ne donneront lieu à aucune indemnisation.
              </li>
              <li>
                <span>9.2.</span> L’obligation de la Société est une obligation
                de moyen. Il ne peut être tenu en conséquence responsable en cas
                de :
                <ul>
                  <li>
                    cessation sur décision de l’autorité publique ou d’un cas
                    fortuit,
                  </li>
                  <li>
                    prestations rendues par des prestataires de services
                    indépendants autres que ceux avec qui la Société ont
                    contractés. Toute réclamation concernant ces services doit
                    être adressée directement aux prestataires les ayant rendus
                  </li>
                </ul>
              </li>
              <li>
                <span>9.3.</span> La Société ne pourra pas être tenu pour
                responsable de tout dommage direct ou indirect notamment les
                pertes de profit, de clientèle ou de données, les pertes
                d’exploitation et les préjudices commerciaux, ou toute autre
                perte de biens incorporels pouvant résulter de l’utilisation ou
                de l’interruption du service.
              </li>
            </ul>
            <h6>Article 10 : CLAUSES DE CONFIDENTIALITES</h6>
            <ul class="no-bullets">
              <li>
                <span>10.1.</span> Dans le cadre de cet accord, on entendra par
                information confidentielle toutes données, fichiers, schémas,
                échantillons, prototypes, documentations ou informations
                relatives aux produits ou à l’activité de l’une ou l’autre des
                parties, quel que soit leur nature et la forme sous laquelle
                elles sont transmises, oralement ou par écrit.
              </li>
              <li>
                <span>10.2.</span> Les parties s'engagent à :
                <ul>
                  <li>
                    Prendre à l'égard de l'information confidentielle toutes les
                    précautions et tout le soin qu'elles prendraient à l'égard
                    de ses propres informations confidentielles pour empêcher
                    leur divulgation,
                  </li>
                  <li>
                    Maintenir l'information confidentielle en leur possession,
                    limiter l'accès à cette information confidentielle à leurs
                    seuls employés pouvant en avoir besoin pour mener à bien
                    leurs missions et obtenir l'engagement express desdits
                    salariés de ne pas communiquer l'information à des tiers,
                  </li>
                  <li>
                    Ne pas divulguer ni transmettre tout ou une partie de
                    l'information confidentielle à un tiers sans l'accord écrit
                    préalable de l’autre partie
                  </li>
                </ul>
              </li>
              <li>
                <span>10.3.</span> Le présent engagement de secret ne s'applique
                cependant pas aux informations dont une partie pourra donner la
                preuve :
                <ul>
                  <li>
                    Qu'elles lui appartenaient avant leur divulgation par
                    l’autre partie et / ou
                  </li>
                  <li>
                    Qu'elles ont été mises à la disposition du public sans faute
                    de sa part, et / ou
                  </li>
                  <li>
                    Qu'elles ont été développées indépendamment par elle sans
                    utiliser l'information confidentielle, et / ou
                  </li>
                  <li>
                    Qu'elles lui ont été transmises par un tiers sans obligation
                    de secret.
                  </li>
                </ul>
              </li>
              <li>
                <span>10.4.</span> Aucune disposition des présentes ne peut être
                interprétée comme accordant au Client une licence quelconque
                pour l'utilisation de l’information confidentielle, brevetée ou
                non, ou comme entraînant une quelconque obligation pour les
                parties de négocier ou de conclure un quelconque accord.
              </li>
              <li>
                <span>10.5.</span> Sauf mention expresse, en contractant avec la
                Société, le Client lui donne le droit de le citer comme
                Référence, Partenaire ou Client. L'utilisation de sa Raison
                Sociale, un lien entrant vers ses plateformes digitales et ses
                identités visuelles est accordé à la Société pour ces citations.
              </li>
              <li>
                <span>10.6.</span> Il est convenu entre les parties que
                l'information communiquée par une partie dans le cadre des
                présentes demeure la propriété de cette partie et devra lui être
                retournée immédiatement à l'expiration de cet accord, ou sur une
                simple demande écrite.
              </li>
              <li>
                <span>10.7.</span> Les parties s'engagent à tenir
                confidentielles, tant pendant la durée du présent accord
                qu'après son expiration pendant une durée de cinq (5) ans,
                toutes informations dont ils auront connaissance sur l'activité
                de l’autre Partie.<br />
                Le manquement à la clause de confidentialité expose la partie
                fautive à des sanctions pécuniaires et des poursuites
                juridiques.
              </li>
            </ul>
            <h6>Article 11 : APPLICATION DES CONDITIONS GENERALES</h6>
            <ul class="no-bullets">
              <li>
                <span>11.1.</span> Toute personne, toute société et tout client
                <ul>
                  <li>
                    souscrivant à quelconque prestation offerte par la Société
                  </li>
                  <li>
                    utilisant le site et les réseaux sociaux de la Société
                  </li>
                  <li>s'informant sur les prestations de la Société</li>
                  <li>échangeant numériquement avec la Société</li>
                </ul>
                sont soumis à ces Conditions Générales.
              </li>
              <li>
                <span>11.2.</span> Ces Conditions Générales sont applicables et
                valent toute durée et territoire.
              </li>
              <li>
                <span>11.3.</span> Toute modification substantielle des
                présentes Conditions Générales sera mise à la disposition des
                clients par voie d’affichage dans les locaux de MK-co, sur le
                site web www.mk-co.org.<br />
                Les modifications sont applicables aux contrats en cours
                d’exécution dès sa publication.
              </li>
            </ul>
            <h6>Article 12 : MEDIATION</h6>
            <p>
              En cas de différend sur l’exécution du présent contrat, les
              parties s’engagent à déployer tous leurs efforts pour régler
              amiablement le litige. Et ne serait valable les procédures
              judiciaires n’ayant pas été soumise à l'étape de médiation,
              justifiée par un procès verbal.<br />
              Les frais de médiation seront supportés par moitié, par chacune
              des parties.<br />
              Toute réclamation doit s’adresser au service administratif de
              MK-co dont l’adresse et le numéro de téléphone figurent sur le
              contrat concerné. Celui-ci s’engage à traiter la réclamation dans
              un délai d’un (1) mois calendaire à compter de la réception de la
              réclamation.
            </p>
            <h6>Article 13 : DISPOSITIONS DIVERSES</h6>
            <ul class="no-bullets">
              <li>
                <span>13.1.</span> Si une quelconque des stipulations du Contrat
                est tenue pour nulle ou sans objet, elle sera réputée non écrite
                et n’entraînera pas la nullité des autres stipulations.
              </li>
              <li>
                <span>13.2.</span> Tout litige susceptible de s’élever entre les
                parties, à propos de la formation, de l’exécution, ou de
                l’interprétation du présent contrat, sera de la compétence
                exclusive du tribunal de commerce d’Antananarivo, Madagascar.
              </li>
            </ul>
          </div>
          <div class="modal-footer bg-dark">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal add début-->
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="siteMap"
      tabindex="-1"
      aria-labelledby="modalLabelSiteMap"
      aria-hidden="true"
    >
      <SiteMap />
    </div>
  </footer>
</template>

<script>
import IcoBell from "vue-material-design-icons/Bell.vue";
import IcoFacebook from "vue-material-design-icons/Facebook.vue";
import IcoInstagram from "vue-material-design-icons/Instagram.vue";
import IcoWhatsapp from "vue-material-design-icons/Whatsapp.vue";
import IcoLinkedin from "vue-material-design-icons/Linkedin.vue";
import IcoUp from "vue-material-design-icons/ChevronUp.vue";
import { createNewsLetter } from "../../api/newsLetter";
import { success, error } from "../../utils/toast";
import SiteMap from "../../components/public/SiteMap.vue";
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

export default {
  name: "TheFooter",
  components: {
    IcoBell,
    IcoFacebook,
    IcoInstagram,
    IcoWhatsapp,
    IcoLinkedin,
    IcoUp,
    SiteMap,
  },
  data() {
    return { form: { mail_news: "" }, scrollPosition: null, services: [] };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["visiteurStore/isLoggedIn"];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    confirm() {
      createNewsLetter(this.form).then((result) => {
        if (result.data.error) {
          error(result.data.error, "");
        } else {
          success("Vous êtes maintenant abonné à MK-CO");
        }
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

footer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/img/bg-footer-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: $light;
}

.iconSocial a {
  cursor: pointer;
  color: $primary;
}

a {
  color: $light;
  text-decoration: none;
  transition: 250ms;

  &:hover {
    color: darken($light, $amount: 25);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}

.retourHaut {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  opacity: 0;
  visibility: hidden;
  transition: 250ms;
  z-index: 10;
}

.retourHautShow {
  opacity: 0.75;
  visibility: visible;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
}

h6 {
  color: $primary;
  font-weight: bold;
}

.modal-body p {
  /* word-spacing: 5px; */
  /* letter-spacing: 3px; */
  font-size: x-small;
}

.modal-body ul li {
  font-size: x-small;
}

.no-bullets {
  list-style-type: none;
  padding: 0;

  span {
    color: $primary;
  }
}
</style>
