<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content border-0 bg-light text-dark">
      <div class="modal-header mx-2">
        <h4 class="modal-title text-primary" id="modalLabelSiteMap">
          Plan du site
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body mx-4">
        <h6 class="text-dark">Pages</h6>
        <ul class="text-dark">
          <li>
            <div
              class="router cursor-pointer"
              :class="path == `/` && 'text-primary'"
              @click="redirectToPage('/')"
            >
              Découvrir
            </div>
          </li>
          <li>
            <h6 class="text-dark">Accompagnements</h6>
            <ul class="text-dark">
              <li v-for="item in services" :key="item.id_service">
                <div
                  class="router cursor-pointer"
                  :class="
                    path == `/accompagnement/${item.slug}` && 'text-primary'
                  "
                  @click="redirectToService(item.slug)"
                >
                  {{ item.title_service }}
                </div>
              </li>
            </ul>
          </li>
          <li>
            <div
              class="router cursor-pointer"
              :class="path == `/offre-emploi` && 'text-primary'"
              @click="redirectToPage('/offre-emploi')"
            >
              Talents
            </div>
          </li>
          <li>
            <div
              class="router cursor-pointer"
              :class="path == `/articles` && 'text-primary'"
              @click="redirectToPage('/articles')"
            >
              S'informer
            </div>
          </li>

          <li>
            <div
              class="router cursor-pointer"
              :class="path == `/se-connecter` && 'text-primary'"
              @click="redirectToPage('/se-connecter')"
            >
              Mon espace
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer mx-2">
        <button data-bs-dismiss="modal" class="btn btn-primary" ref="close">
          Fermer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

export default {
  name: "SiteMap",
  data() {
    return {
      services: [],
    };
  },
  computed: {
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  mounted() {
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    closeModal() {
      this.$refs.close.click();
    },
    redirectToService(slug) {
      this.closeModal();
      this.$router.push(`/accompagnement/${slug}`);
    },
    redirectToPage(url) {
      this.closeModal();
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.router {
  margin-block: 2px;
}

a {
  color: $dark;
  text-decoration: none;
  transition: 250ms;

  &:hover {
    color: darken($light, $amount: 25);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}
</style>
